@font-face {
    font-family: 'Akzidenz-Grotesk BQ';
    src: url(/static/media/AkzidenzGroteskBQ-Ext.10e93849.woff) format('woff2'),
        url(/static/media/AkzidenzGroteskBQ-Ext.10e93849.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Akzidenz-Grotesk BQ';
    src: url(/static/media/AkzidenzGroteskBQ-MedExt.97910c1d.woff2) format('woff2'),
        url(/static/media/AkzidenzGroteskBQ-MedExt.8875d321.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}

body {
  font-family: 'Akzidenz-Grotesk BQ', sans-serif;
  line-height: 1.5;
  letter-spacing: 0.1rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

#root {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.card {
  position: absolute;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  left: 10vw;
  top: 60%;
  width: 12vw;
  height: 12vw;
}

.card2 {
  top: 15vh;
  right: 10vw;
  width: 10vw;
  height: 10vw;
}

.card3 {
  right: 5vw;
  top: 70%;
  width: 12vw;
  height: 12vw;
}

img {
  max-width: 100%;
  height: auto;
}

a,
a:visited {
  color: white;
  text-decoration: none;
  transition: 0.2s;
}

a:hover {
  color: darkred;
}


.burger {
  display: block;
  position: fixed;
  top: 2rem;
  left: 2rem;
  z-index: 9999999999999;
  -webkit-user-select: none;
  user-select: none;
}

.burger input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
.burger span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #ECEFCA;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.burger span:first-child {
  transform-origin: 0% 0%;
}

.burger span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
.burger input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-10px, -10px);
  background: white;
}

/*
 * But let's hide the middle one.
 */
.burger input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
.burger input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(-10px, 10px);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: darkred;
  padding: 0 0 2rem;
}

.cta {
  font-weight: 500;
  color: white;
  margin: 2rem auto;
  text-align: center;
  font-size: 0.75rem;
}

.parallax-home .card1 {
  height: 33vh;
  width: 33vw;
  left: 33%;
}

.menu-item {
  display: block;
  padding: 0 2rem;
  text-align: left;
  font-size: 0.6rem;
  line-height: 1.3;
}

.menu-item img {
  transition: 0.2s;
}

.menu-item:hover {
  color: #ECEFCA;
}

.menu-item:hover img {
  transform: scale(1.1);
}

.container {
  position: relative;
  top: 50%;
  transform: translateY(-52%);
  z-index: 5;
}

.container.home {
  top: 40%;
}

.page {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  z-index: 
}

.links-title {
  font-weight: 500;
  text-align: right;
  text-transform: uppercase;
  font-size: 0.6rem;
  color: white;
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}

.links li {
  display: inline-block;
  margin: 0 0.5rem;
}

.links li.apple {
  width: 100px;
}

.links li.spotify {
  width: 80px;
}

.links li.amazon {
  width: 22px;
}

.links li.pandora {
  width: 20px;
}

.links img {
  transition: 0.2s;
}

.links img:hover {
  transform: scale(1.1);
}

.parallax {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  pointer-events: none;
}

.yt-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  margin: 1rem auto 0;
  height: 0;
}

.yt-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 25px rgba(255, 255, 255, 0.5);
}

.yt-title {
  font-size: 0.8rem;
  color: white;
  margin: 0.25rem auto;
  text-align: center;
  height: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, .75);
}

.controls-desktop {
  display: block;
  text-align: center;
}

.controls-mobile {
  display: none;
  text-align: center;
}

.control-btn {
  display: inline-block;
  max-width: 30px;
  transition: 0.2s;
  margin: 0.5rem;
  cursor: pointer;
}

.control-btn:hover {
  transform: scale(1.2);
}

footer {
  position: fixed;
  bottom: 1rem;
  left: 0;
  padding-left: 1rem;
  z-index: 25;
  width: 100%;
  text-align: left;
}

.attribution {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.5rem;
  text-transform: uppercase;
  color: #ECEFCA;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, .75);
}

.attribution li {
  margin: 0 0.25rem;
  display: inline-block;
}

.attribution a,
.attribution a:visited {
  color: #D1E6F4;
}

@media (min-width: 320px) and (max-width: 480px) {

  #root {
    overflow: auto;
  }

  header {
    position: absolute;
  }

  header .cta {
    font-size: 0.5rem;
    padding: 0 5rem;
  }

  .card {
    display: none;
  }

  .container {
    text-align: center;
  }

  .links-title {
    text-align: center;
  }

  .container.home {
    top: 50%;
  }

  .container.home .menu-item {
    margin: 1rem auto;
  }

  .container.home .menu-item p {
    display: none;
  }

  .menu-item {
    margin-bottom: 1rem;
  }

  .page-title {
    max-width: 90%;
    margin-bottom: 1rem;
  }

  .parallax-home .card1 {
    height: 50%;
    width: 50%;
  }


  .controls-desktop {
    display: none;
  }

  .controls-mobile {
    display: block;
  }

  .links,
  footer {
    text-align: center;
  }

  footer {
    padding-left: 0;
    bottom: 1rem;
  }
  
}

